.header {
    text-align: center;
    padding: 10px;
}

.header>h1 {
    margin: 5px;
}

.header .links {
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: center;
}

.links a {
    color: black;
    margin: 5px 10px;
}
