@import url('https://fonts.googleapis.com/css?family=Open+Sans');

body {
  font-family: 'Open Sans';
}

.home {
    text-align: center;
}

.blogs {
    text-align: center;
}

.blogs .ideas {
    text-align: left;
    padding: 0px 35vw;
}
